/*
 * @Author: shanshan 1752747088@qq.com
 * @Date: 2023-08-24 15:03:02
 * @LastEditors: shanshan 1752747088@qq.com
 * @LastEditTime: 2023-08-25 11:22:11
 * @FilePath: \dxzx-form-h5\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: { pageTitle: '产品详情', backgroundColor: '#F04537' }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/pages/driver-form',
    name: 'driver-form',
    props: { pageTitle: '车主详情', backgroundColor: '#F04537' },
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/driver-form.vue')
  },
  {
    path: '/pages/enterprise-form',
    name: 'enterprise-form',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/enterprise-form.vue')
  },
  {
    path: '/pages/result',
    name: 'result',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/result.vue')
  },
  {
    path: '/staging',
    name: 'staging',
    component: () => import(/* webpackChunkName: "about" */ '../views/staging.vue')
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: () => import(/* webpackChunkName: "about" */ '../views/insurance.vue')
  },
  {
    
    path: '/transport',
    name: 'transport',
    component: () => import(/* webpackChunkName: "about" */ '../views/transport.vue')
  },
  {
    
    path: '/upLoadFile',
    name: 'upLoadFile',
    component: () => import(/* webpackChunkName: "about" */ '../views/upLoadFile.vue')
  },
  {
    
    path: '/submitSuccess',
    name: 'submitSuccess',
    component: () => import(/* webpackChunkName: "about" */ '../views/submit-success.vue')
  },
  {
    
    path: '/pass',
    name: 'pass',
    component: () => import(/* webpackChunkName: "about" */ '../views/pass.vue')
  },
  {
    
    path: '/refuse',
    name: 'refuse',
    component: () => import(/* webpackChunkName: "about" */ '../views/refuse.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
